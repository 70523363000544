
.settingsPage {
  .boxFile {
    .input-group {
      background-color: #4284f4;
      border-color: 1px solid #4284f4;
      border-radius: 0.3rem;
      color: #fff;
      input[type='file'] {
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 9px;
        left: calc(50% - 45px);
        font-weight: 600;
        color: #fff;
        pointer-events: none;
        content: 'Upload File';
      }
      &:hover {
        background-color: #1e6cf2;
        border-color: #1265f1;
        &::before {
          color: #fff;
        }
      }
    }
    .form-control {
      opacity: 0;
    }
  }
}
#MazPhoneNumberInput {
  .select-country-container{
    flex: 0 0 85px;
    width: 85px;
    min-width: 85px;
    max-width: 85px;
  }
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
